<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-card class="rounded-lg secondarygrad">
      <v-row>
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <!-- <h2 style="margin-bottom: 30px">
                        Lorem ipsum
                      </h2> -->
              <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                <h1 style="margin-bottom: 40px">Godial + Interakt</h1>
              </div>
              <div style="padding: 5px 15px; text-align: center">
                <!-- <h5
                          style="
                            line-height: 17px;
                            font-weight: 500;
                            font-size: 14px;
                          "
                        >
                          How to use?
                        </h5> -->

                <div style="text-align: left">
                  <p
                    style="
                              margin-top: 20px;
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                            "
                  >
                    How to use?
                  </p>
                  <p>
                    1. Enable Interakt
                    <br />
                    2. Enter your key and click save button
                    <br />
                    3. That is all you need to setup a Interact integration
                  </p>
                </div>
                <!-- <p class="text-left">
                          For more details visit :
                          <a
                            target="_blank"
                            href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                          >
                            this link</a
                          >
                        </p> -->
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p
                  style="
                            font-size: 12px;
                            opacity: 0.9;
                            margin-bottom: 0px;
                          "
                >
                  Feel free to reach out to us at
                  <a href="mailto:support@godial.cc">support@godial.cc</a>
                  if you are interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat class="transparent">
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex
                  d-flex
                  justify-center
                  class="mt-10"
                  style="padding-top: 22px"
                  >Enable Interakt
                </v-flex>
                <v-flex class="mt-10" d-flex justify-center>
                  <v-switch
                    v-model="interakt.enabled"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="margin-top:25px; padding: 15px 50px; text-align: center"
          >
            <v-card-title primary-title class="justify-center"
              >Interakt Credentials</v-card-title
            >

            <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <v-text-field
                v-model="interakt.config.key"
                :disabled="!interakt.enabled"
                label="API Key"
                type="text"
                rows="3"
                prepend-inner-icon="vpn_key"
              ></v-text-field>
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn color="primarygrad" dark block @click="setInterakt"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      color="transparent"
      elevation="0"
      style="padding-top: 30px; text-align: center !important"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :search="search"
        :items="interakt.config.events"
        hide-select
        class="elevation-1 transparent"
      >
        <template v-slot:top>
          <v-layout justify-center>
            <div class="text-xs-center">
              <div class="d-flex justify-center">
                <v-chip
                  style="margin: 7px"
                  color="green"
                  text-color="white"
                  @click="openDialog"
                >
                  <v-icon left color="white">add</v-icon>Add Events
                </v-chip>
              </div>
            </div>
          </v-layout>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon small fab text v-on="on">
                <v-icon :size="20" @click="deleteItem(item)">delete</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="eventDialog" persistent width="500">
      <v-card>
        <v-card-title class="primary white--text">
          Add Events
        </v-card-title>
        <v-card-text style="padding: 15px">
          <v-row style="margin: 0px">
            <v-flex xs12 sm12 md12 style="padding: 3px 8px">
              <v-text-field v-model="eventName" label="Event *"></v-text-field>
            </v-flex>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="eventDialog = false">close</v-btn>
          <v-btn color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
  data() {
    return {
      eventDialog: false,
      eventName: "",
      interakt: {
        type: "interakt",
        config: {
          key: "",
          events: [],
        },
        events: [],
        companyId: "",
        enabled: false,
      },
      loader: false,
      lists: [],
      search: "",
      selected: [],
      headers: [
        {
          text: "Event Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Action", align: "center", sortable: false, value: "action" },
      ],
    };
  },

  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  watch: {},
  created() {
    this.getInteraktDetails();
    this.interakt.companyId = this.companyDetails.id;
  },
  methods: {
    deleteItem(delItem) {
      this.interakt.config.events = this.interakt.config.events.filter(
        (item) => item.name !== delItem.name
      );

      this.setInterakt();
      // this.selected = [];
    },
    save() {
      if (this.eventName) {
        this.interakt.config.events.push({ name: this.eventName });

        console.log("this.interakt.config.events", this.interakt.config.events);
        this.setInterakt();

        this.eventName = "";
        this.eventDialog = false;
        this.getInteraktDetails();
      }
    },
    openDialog() {
      this.eventDialog = true;
    },
    async getInteraktDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=interakt`
      );

      if (resp.body.length > 0) this.interakt = resp.body[0];
      // this.interakt.config.events = [];
    },

    async setInterakt() {
      this.loader = true;

      try {
        this.interakt.companyId = this.companyDetails.id;
        this.interakt.type = "interakt";
        this.interakt.config.key = this.interakt.config.key;
        var res = {};

        //If new user
        if (typeof this.interakt.id == "undefined") {
          // Add Indiamart Details In Company Data
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.interakt
          );
        } else {
          var data = JSON.parse(JSON.stringify(this.interakt));
          delete data.id;
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.interakt.id}`,
            data
          );
        }

        // Success Message
        if (res.status == 200) {
          // Set Company Object for Store dispatch
          this.getInteraktDetails();
          this.loader = false;

          try {
            fb.log("interakt_added");
          } catch (e) {
            console.log("Error in FB log", e);
          }

          return this.$swal({
            type: "success",
            text: "Interakt Added to Godial Successfully",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err,
        });
      }
    },
  },
};
</script>

<style></style>
